<template>
  <section>
    <a-form class="form-container m-t-18" :form="form">
      <section class="form-item">
        <p class="title">项目信息</p>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="项目名称">
              <a-input
                placeholder="请输入"
                v-decorator="['projectName',{rules:[{required:true,message:'请输入项目名称'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="施工许可证">
              <a-input
                placeholder="请输入"
                v-decorator="['licenseNo',{rules:[{required:true,message:'请输入施工许可证'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="GIS经纬度">
              <a-input-search
                enterButton="选点"
                @search="showMapModal"
                read-only
                placeholder="请选择"
                v-decorator="['gisX',{rules:[{required:true,message:'请选择GIS经纬度'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="项目所在地">
              <a-input
                read-only
                placeholder="请选择"
                v-decorator="['projectSite',{rules:[{required:true,message:'请输入项目所在地'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="详细地址">
              <a-input
                placeholder="请输入"
                v-decorator="['projectAddress',{rules:[{required:true,message:'请输入详细地址'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="建设面积(平方米)">
              <a-input
                placeholder="请输入"
                v-decorator="['constructionArea',{rules:[{required:true,message:'请输入建设面积'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="市合同备案编号">
              <a-input
                placeholder="请输入"
                v-decorator="['contractNo',{rules:[{required:false,message:'请输入市合同备案编号'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="合同金额(万元)">
              <a-input
                placeholder="请输入"
                v-decorator="['contractAmount',{rules:[{required:false,message:'请输入合同金额'},{validator:validator.moneyReg}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="预付款金额(万元)">
              <a-input
                placeholder="请输入"
                v-decorator="['advancePayment',{rules:[{required:false,message:'请输入预付款金额'},{validator:validator.moneyReg}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="清单人工费(万元)">
              <a-input
                placeholder="请输入"
                v-decorator="['laborCost',{rules:[{required:false,message:'请输入清单人工费'},{validator:validator.moneyReg}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="安全文明施工措施费金额(万元)">
              <a-input
                placeholder="请输入"
                v-decorator="['saftyCost',{rules:[{required:false,message:'请输入安全文明施工措施费金额'},{validator:validator.moneyReg}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="项目性质">
              <a-select
                placeholder="请选择"
                v-decorator="['projectNature',{rules:[{required:true,message:'请选择项目性质'}]}]"
              >
                <a-select-option :value="1">房建</a-select-option>
                <a-select-option :value="2">市政</a-select-option>
                <a-select-option :value="3">轨道</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开工日期">
              <a-date-picker
                :disabledDate="disabledStartDate"
                placeholder="请选择"
                v-decorator="['startDate',{rules:[{required:true,message:'请选择开工日期'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="计划竣工日期">
              <a-date-picker
                :disabledDate="disabledEndDate"
                placeholder="请选择"
                v-decorator="['endDate',{rules:[{required:true,message:'请选择开工日期'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="实际竣工日期">
              <a-date-picker
                :disabledDate="disabledEndDate"
                placeholder="请选择"
                v-decorator="['actualDate',{rules:[{required:false,message:'请选择开工日期'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="所属住建局">
              <a-select
                placeholder="请选择"
                v-decorator="['thirdPartyId',{rules:[{required:false,message:'请选择所属住建局'}]}]"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in thirdPartyList"
                  :key="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <section class="form-item m-t-10">
        <div class="form-btn-group" style="text-align:center;padding:18px 0">
          <a-button @click="submit" :loadding="submitLoadding" :disabled="submitLoadding">
            <a-icon v-if="!submitLoadding" type="save" />保存
          </a-button>
          <a-button @click="$router.back()">取消</a-button>
        </div>
      </section>
    </a-form>

    <a-modal
      centered
      width="1080px"
      class="modal-container"
      :title="mapModal.title"
      v-model="mapModal.visible"
      :footer="false"
    >
      <section class="form-container">
        <baidu-map
          style="height:490px;width:100%"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          @click="getClickInfo"
          @zoomend="syncCenterAndZoom"
          ak="0FuoX30MFf7YMrdS5Wi9GGAcHBblKDuu"
        >
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
          ></bm-geolocation>
          <bm-marker
            size="100"
            animation="BMAP_ANIMATION_BOUNCE"
            :position="{lng: markPosition.lng, lat: markPosition.lat}"
            :dragging="true"
          ></bm-marker>
        </baidu-map>
        <a-row class="form-btn-group m-t-18">
          <a-button @click="saveGis">保存</a-button>
          <a-button @click="cancelGis">取消</a-button>
        </a-row>
      </section>
    </a-modal>
  </section>
</template>

<script>
import moment from 'moment'
import validator from '@/until/validator'
import { BaiduMap, BmGeolocation, BmMarker } from "vue-baidu-map";
export default {
  data() {
    return {
      mapModal: {
        visible: false,
        title: 'gis选点',

      },
      markPosition: { lng: 114.025973657, lat: 22.5460535462 },
      center: { lng: 114.025973657, lat: 22.5460535462 },
      zoom: 12,
      thirdPartyList: [],
      validator
    }
  },
  components: { BaiduMap, BmGeolocation, BmMarker },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  created() {
    this.queryThirdParty()
  },
  mounted() {
    this.pid && this.queryProject()
  },
  computed: {
    submitLoadding() {
      return this.$store.state.submitLoadding
    },
    pid() {
      return this.$route.query.id
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const outDate = this.form.getFieldValue("endDate");
      if (!startValue || !outDate) return false;
      return startValue.valueOf() > outDate.valueOf();
    },
    disabledEndDate(endValue) {
      const inDate = this.form.getFieldValue("startDate");
      if (!inDate || !endValue) return false;
      return inDate.valueOf() >= endValue.valueOf();
    },
    //住建局列表
    queryThirdParty() {
      this.$api.queryThirdParty({ category: 1 }).then(res => {
        if (res.code === 200) {
          this.thirdPartyList = res.data || []
        }
      })
    },
    showMapModal() {
      this.mapModal.visible = true
    },
    getClickInfo(e) {
      this.markPosition.lng = e.point.lng;
      this.markPosition.lat = e.point.lat;
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.center.lng = lng;
      this.center.lat = lat;
      this.zoom = e.target.getZoom();
    },
    saveGis() {
      var gc = new BMap.Geocoder();
      let _this = this;
      let lng = _this.markPosition.lng
      let lat = _this.markPosition.lat
      setTimeout(() => {
        var point = new BMap.Point(lng, lat);
        gc.getLocation(point, function (rs) {
          let addressComponents = rs.addressComponents || {}
          _this.form.setFieldsValue({
            projectSite: addressComponents.province + addressComponents.city,
            projectAddress: rs.address,
            gisX: lng + ',' + lat
          });
        });
      }, 100);
      this.mapModal.visible = false
    },
    cancelGis() {
      this.mapModal.visible = false
    },
    queryProject() {
      this.$api.queryProject().then(res => {
        if (res.code === 200) {
          let info = res.data || {}
          let formData = {
            projectName: info.projectName,
            contractAmount: info.contractAmount,
            saftyCost: info.saftyCost,
            projectNature: info.projectNature,
            projectAddress: info.projectAddress,
            licenseNo: info.licenseNo,
            startDate: info.startDate ? moment(info.startDate) : null,
            endDate: info.endDate ? moment(info.endDate) : null,
            actualDate: info.actualDate ? moment(info.endDate) : null,
            gisX: (info.gisX + ',' + info.gisY),
            advancePayment: info.advancePayment,
            constructionArea: info.constructionArea,
            projectSite: info.projectSite,
            contractNo: info.contractNo,
            laborCost: info.laborCost,
            thirdPartyId: info.thirdPartyId
          }
          this.center.lng = info.gisX
          this.center.lat = info.gisY
          this.markPosition.lng = info.gisX
          this.markPosition.lat = info.gisY
          this.form.setFieldsValue(formData)
        }
      })
    },
    submit() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          this.$store.commit('SET_SUBMITLOADDING', true)
          let gis = formData.gisX ? formData.gisX.split(',') : []
          formData.gisX = gis.length ? gis[0] : ''
          formData.gisY = gis.length ? gis[1] : ''
          formData.startDate = formData.startDate ? formData.startDate.format('YYYY-MM-DD') : ''
          formData.endDate = formData.endDate ? formData.endDate.format('YYYY-MM-DD') : ''
          formData.actualDate = formData.actualDate ? formData.actualDate.format('YYYY-MM-DD') : ''
          if (this.pid) {
            formData.id = this.pid
          }
          let apiName = this.pid ? 'updateProject' : 'insertProject'
          this.$api[apiName](formData).then(res => {
            if (res.code === 200) {
              this.$router.back()
            }
          })
        }
      })
    }
  }
}
</script>